import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const MemberList = () => {
  let slider = null

  const memberList = [
    {
      category: "spain",
      name: "tomeu ferran",
      position: "office manager",
      image: "member_tomeu-ferran",
    },
    {
      category: "spain",
      name: "yasmin castillo",
      position: "landscape designer",
      image: "member_yasmin-castillo",
    },
    {
      category: "spain",
      name: "samuel ferran",
      position: "cordinator",
      image: "member_samuel-ferran",
    },
    {
      category: "japan",
      name: "tamaki tokuda",
      position: "landscape designer",
      image: "member_tamaki-tokuda",
    },
    {
      category: "japan",
      name: "hikari sugihara",
      position: "assistant landscape designer",
      image: "member_hikari-sugihara",
    },
  ]

  const labelChange = category => {
    const labelList = document.querySelectorAll("[data-member-list-label]")
    labelList.forEach(item => {
      item.classList.remove("is-active")
    })
    const label = document.querySelector(
      `[data-member-list-label="${category}"]`
    )
    label.classList.add("is-active")
  }

  const slickSettings = {
    arrows: false,
    dots: false,
    fade: false,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    speed: 350,
    pauseOnFocus: true,
    pauseOnHover: true,
    // pauseOnDotsHover: true,
    lazyLoad: false,
    centerMode: false,
    // centerPadding: "0px",
    slidesToShow: 3.08888,
    variableWidth: true,
    adaptiveHeight: true,
    afterChange: currentSlide => {
      const belong = memberList[currentSlide].category
      labelChange(belong)
    },
    responsive: [
      {
        breakpoint: 812,
        settings: {
          slidesToShow: 1.6,
          variableWidth: true,
        },
        // afterChange: (currentSlide) => {
        // 	const belong = memberList[currentSlide].category;
        // 	labelChange(belong);
        // },
      },
    ],
  }

  return (
    <div id="member-list" className="member-list" data-scroll="">
      <p className="member-list__label">
        <span
          className="member-list__label-item en is-active"
          data-member-list-label="spain"
        >
          spain team
        </span>
        <span
          className="member-list__label-item en"
          data-member-list-label="japan"
        >
          japan team
        </span>
      </p>
      <div className="member-list__arrow pc">
        <button
          className="member-list__arrow--prev"
          onClick={() => slider.slickPrev()}
        ></button>
        <button
          className="member-list__arrow--next"
          onClick={() => slider.slickNext()}
        ></button>
      </div>
      <Slider ref={c => (slider = c)} {...slickSettings}>
        {memberList.map((item, i) => {
          return (
            <div
              key={i}
              className="member-list__item"
              data-member-list-category={item.category}
            >
              <div className="member-list__item-image">
                <img src={`/image/about/member/${item.image}.jpg`} alt="" />
              </div>
              <p className="member-list__item-credit">
                <span className="member-list__item-name en">{item.name}</span>
                <span className="member-list__item-position en">
                  {item.position}
                </span>
              </p>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default MemberList
